<script lang="ts">
  import { priceToString } from 'lib/l10n';
  import { Readable } from 'svelte/store';
  import { TextSize } from '@svelte/components/atoms/typography/props';
  import CartIcon from '@svelte/components/atoms/icons/cart/CartStatic.svelte';
  import CartItem from '@svelte/components/molecules/checkout/cart-item/CartItem.svelte';
  import CheckoutAmounts from '../amounts/CheckoutAmounts.svelte';
  import checkoutStoreDefault, { CheckoutState } from 'state/stores/checkout';
  import Chevron from '@svelte/components/atoms/icons/Chevron.svelte';
  import Container from '@svelte/components/atoms/layout/Container.svelte';
  import FreeDeliveryProgress from '@svelte/components/molecules/checkout/free-delivery-progress/FreeDeliveryProgress.svelte';
  import InterfaceText from '@svelte/components/atoms/typography/InterfaceText/InterfaceText.svelte';
  import animatedDetails from '@svelte/components/atoms/disclosure/animatedAction';

  export let readOnly = false;
  export let checkoutStore: Readable<CheckoutState> | null = null;
  // NOTE: two way binding is not possible with testing library
  // exporting only for
  export let isOpen = false;
  const _checkoutStore = checkoutStore || checkoutStoreDefault;

  $: ({ cart, amounts } = $_checkoutStore);
  $: skus = cart?.products.flatMap(p => p.skus) || [];

  $: summaryLabel = `${isOpen ? 'Hide' : 'Show'} order summary`;
</script>

<details
  bind:open={isOpen}
  use:animatedDetails
  class="cursor-pointer overflow-hidden border-b border-pearl-800 bg-pearl-200"
>
  <Container as="summary" className="py-rhythm1 flex justify-between">
    <span class="flex-cnt">
      <CartIcon className="fill-pearl-800 w-4 h-4 inline mr-2" />
      <InterfaceText size={TextSize.LongPrimer} className="mr-2"
        >{summaryLabel}</InterfaceText
      >
      <Chevron
        direction={isOpen ? 'up' : 'down'}
        className="fill-pearl-800 w-4 h-4 inline"
      />
    </span>
    <InterfaceText size={TextSize.LongPrimer} aria-hidden="true"
      >{priceToString(amounts.total)}</InterfaceText
    >
  </Container>
  <Container
    as="content"
    className="flex flex-col space-y-rhythm1 py-rhythm1 border-t border-pearl-600"
  >
    <FreeDeliveryProgress compact />
    <ul
      class="max-h-rhythm10 overflow-y-auto scrollbar-thin scrollbar-track-primary-300 scrollbar-thumb-primary"
    >
      {#each skus as sku (sku.data.id)}
        <CartItem {readOnly} sku={sku.data} qty={sku.qty} />
      {/each}
    </ul>
    <CheckoutAmounts />
  </Container>
</details>
