import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';

import { AppErrorName } from 'lib/events/errors';
import { AppEventName } from 'lib/events/contracts';
import { AppSiteNavItem } from 'gatsby/types';
import { CheckoutLoader } from 'components/molecules/orders/checkout/CheckoutLoader';
import { CheckoutWrapper } from 'components/molecules/orders/checkout/CheckoutWrapper';
import { FormGroupHeading } from 'components/molecules/forms/Groups';
import Purchase24 from '@carbon/icons-react/es/purchase/24';
import { RouteComponentProps } from '@reach/router';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import StripeProvider from 'components/atoms/payments/StripeProvider';
import { SubmitButton } from 'components/atoms/button';
import { Transition } from '@headlessui/react';
import { ValidationMessage } from 'components/molecules/forms/Error';
import { appEventDispatcher } from 'lib/events/Provider';
import { appendQueryParams } from 'lib/navigation/queryParams';
import checkoutStore from 'state/stores/checkout';
import { deliveryRulesStore } from '@svelte/state/deliveryRules';
import { isCartPaymentReady } from './query';
import { loggers } from 'lib/log';
import { makeClassNames } from 'lib/util';
import { navigate } from 'gatsby';
import useAppEventDispatcher from 'lib/events/hooks';
import { useCheckoutRoutingChecks } from 'components/molecules/orders/checkout/hooks';
import { useGraphqlWorker } from 'state/context/GraphqlWorkerProvider';
import { useQuery } from '@svelte/reactify/useQuery';
import { useReadable } from 'lib/react-svelte/reactifyStores';
import { NavigationMenuQuery } from '@svelte/service/cms/graphql/gql';

const log = loggers.ui;

const stripeElementsOpts: StripePaymentElementOptions = {
  fields: {
    billingDetails: {
      email: 'never',
      address: 'never'
    }
  }
};

type PaymentProps = {
  nextRoute: AppSiteNavItem;
  stripeClientSecret: string;
};

const enum PaymentReadinessCheck {
  Unknown,
  Checking,
  Ready
}

const PaymentForm: React.FC<PaymentProps> = ({
  nextRoute,
  stripeClientSecret
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const checkoutState = useReadable(checkoutStore);
  const deliveryRules = useReadable(deliveryRulesStore);
  const serviceWorker = useGraphqlWorker();
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const [submittingPayment, setSubmittingPayment] = useState(false);
  const [stripePaymentFormReady, setStripePaymentFormReady] = useState(false);
  const [stripePaymentMounted, setStripePaymentMounted] = useState(false);
  const eventDispatcher = useAppEventDispatcher();
  const [paymentReadinessCheck, setPaymentReadinessCheck] = useState(
    PaymentReadinessCheck.Unknown
  );
  const { cart: localCart } = checkoutState;

  useEffect(() => {
    const timer = setTimeout(() => {
      setPaymentReadinessCheck(ready => {
        if (!ready) {
          log.error(
            new Error('Unable to take payment. Readiness check failed after 5s')
          );
        }
        return ready;
      });
    }, 5000);

    () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const { cart, amounts } = checkoutState;
    if (
      paymentReadinessCheck === PaymentReadinessCheck.Unknown &&
      cart &&
      deliveryRules
    ) {
      setPaymentReadinessCheck(PaymentReadinessCheck.Checking);

      isCartPaymentReady(amounts.total, deliveryRules, serviceWorker).then(
        ready => {
          if (ready) {
            setPaymentReadinessCheck(PaymentReadinessCheck.Ready);
          } else {
            setTimeout(() => {
              setPaymentReadinessCheck(PaymentReadinessCheck.Unknown);
            }, 1000);
          }
        }
      );
    }
  }, [checkoutState, paymentReadinessCheck]);

  useEffect(() => {
    if (stripePaymentFormReady) {
      eventDispatcher.dispatch(AppEventName.StripeFormLoaded);
    }
  }, [stripePaymentFormReady]);

  useEffect(() => {
    if (formErrorMessage) {
      log.error(new Error('Stripe form is showing form error message'), {
        formErrorMessage,
        localCart
      });
    }
  }, [formErrorMessage]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (paymentReadinessCheck === PaymentReadinessCheck.Ready) {
      stripe
        .retrievePaymentIntent(stripeClientSecret)
        .then(({ paymentIntent }) => {
          switch (paymentIntent?.status) {
            case 'succeeded':
              log.error(
                new Error(
                  'Stripe payment succeeded but order payment status is not updated'
                ),
                { paymentIntent }
              );
              setFormErrorMessage('Payment already succeeded!');
              return;
            case 'processing':
              log.error(
                new Error(
                  "Stripe payment is processing but order's payment status already set"
                ),
                { paymentIntent }
              );
              setFormErrorMessage('Your payment is processing.');
              return;
            case 'requires_payment_method':
              log.info('Payment intent created. Ready to take payment method.');
              break;
            case 'requires_action':
              /**
               * Incomplete action. Example:
               *
               * 1. 3D authentication modal open
               * 2. Something goes bad while modal is open
               */
              setFormErrorMessage('Please try again');
              eventDispatcher.dispatch(AppEventName.StripePaymentFailed, {
                paymentIntent
              });
              break;
            default:
              log.error(new Error('Unknown stripe payment intent status'), {
                paymentIntent
              });
              setFormErrorMessage('Something went wrong.');
              eventDispatcher.dispatchError(
                AppErrorName.StripePaymentFailedUnexpectedly,
                {
                  paymentIntentId: paymentIntent?.id
                }
              );
              break;
          }
        });
    }
  }, [stripe, paymentReadinessCheck]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    if (!localCart) {
      throw Error('Unable to submit payment without cart');
    }

    const { email, id: orderId, billingAddress, deliveryAddress } = localCart;
    const stripeAddress = billingAddress || deliveryAddress;

    if (!stripe || !elements || !stripeAddress || !email || !orderId) {
      setFormErrorMessage(
        'Something is not right. Please reload and try again'
      );
      log.error(new Error('Payment form submitting before being ready'), {
        stripeLoaded: !!stripe,
        elementsLoaded: !!elements,
        email,
        stripeAddress,
        orderId
      });
      return;
    }

    eventDispatcher.dispatch(AppEventName.StripePaymentInProgress);
    setSubmittingPayment(true);

    try {
      const returnUrl = appendQueryParams(nextRoute.path, { orderId });
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${location.protocol + '//' + location.host}${returnUrl}`,
          payment_method_data: {
            billing_details: {
              address: {
                city: stripeAddress.administrativeLevel2,
                country: stripeAddress.country,
                line1: stripeAddress.line1,
                line2: stripeAddress.line2 || '',
                postal_code: stripeAddress.postalCode,
                state: stripeAddress.administrativeLevel1 || ''
              },
              email
              // phone: ''
            }
          }
        },
        redirect: 'if_required'
      });

      if (!error) {
        appEventDispatcher.dispatch(AppEventName.PurchaseCompleted);
        return navigate(returnUrl);
      }

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error && error.payment_intent?.status === 'succeeded') {
        log.warn('Payment already succeeded', { error });
        setFormErrorMessage(
          'Payment already processed. Please contact us to check status'
        );
      } else if (error.message) {
        log.warn('Payment failed', { error });
        setFormErrorMessage(error.message);
      } else if (error) {
        log.error(new Error('Unexpected stripe error'), { error });
        setFormErrorMessage('An unexpected error occurred.');
      } else {
        log.error(
          new Error(
            'Critical unknown state in payments. No error or success detected.'
          )
        );
        setFormErrorMessage('Critical error');
      }
    } catch (error) {
      if (error instanceof Error) {
        log.error(error);
      } else {
        log.error(new Error('Unable to complete payment'), { error });
      }
    } finally {
      setSubmittingPayment(false);
    }
  };

  return (
    <section aria-labelledby="payment-heading">
      <CheckoutLoader
        show={!stripePaymentMounted || submittingPayment}
        message={
          !stripePaymentMounted
            ? 'Loading secure payments interface.'
            : 'Submitting your payment. Please wait.'
        }
      />

      <Transition
        show={stripePaymentMounted && !submittingPayment}
        enter="transition-opacity duration-300 delay-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        <FormGroupHeading Icon={Purchase24} id="payment-heading">
          Payment Details
        </FormGroupHeading>
      </Transition>

      <form
        onSubmit={handleSubmit}
        className={makeClassNames(
          ['w-full', 'transition-opacity', 'duration-300', 'delay-200'],
          stripePaymentMounted ? 'opacity-100' : 'opacity-0'
        )}
      >
        <div
          className={makeClassNames(
            'mt-rhythm3',
            submittingPayment && 'hidden'
          )}
        >
          <PaymentElement
            options={stripeElementsOpts}
            onLoaderStart={() => setStripePaymentMounted(true)}
            onLoadError={error => {
              log.error(new Error('Unable to load Stripe payment'), {
                error
              });
            }}
            onReady={() => setStripePaymentFormReady(true)}
          />
        </div>

        {/* Loading button has a delay so that stripe Elements renders and creates height */}
        <Transition
          className="mt-rhythm3"
          as={'div'}
          color="text-red-600"
          show={stripePaymentFormReady && !submittingPayment}
          enter="transition-opacity duration-300 delay-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <SubmitButton
            disabled={paymentReadinessCheck !== PaymentReadinessCheck.Ready}
            className="w-full"
          />
          <div className="w-full">
            <ValidationMessage message={formErrorMessage} />
          </div>
        </Transition>
      </form>
    </section>
  );
};

type Props = RouteComponentProps &
  Omit<PaymentProps, 'stripeClientSecret'> & {
    title: string;
    footerNavigation: NavigationMenuQuery;
  };

/**
 * PaymentForm makes use of useStripe hook
 * This component is a thin wrapper to load the Provider
 */
export const Payment: React.FC<Props> = ({
  nextRoute,
  title,
  footerNavigation
}) => {
  useCheckoutRoutingChecks();
  const worker = useGraphqlWorker();
  const stripeClientSecretQuery = useQuery({
    key: 'stripeClientSecret',
    variables: {},
    worker
  });
  const stripeClientSecret =
    stripeClientSecretQuery.data?.checkout.stripeClientSecret;

  return (
    <CheckoutWrapper
      footerNavigation={footerNavigation}
      title={title}
      isCart={false}
      canEditItems={false}
    >
      <div className="relative w-full">
        {stripeClientSecret ? (
          <StripeProvider stripeClientSecret={stripeClientSecret}>
            <PaymentForm
              nextRoute={nextRoute}
              stripeClientSecret={stripeClientSecret}
            />
          </StripeProvider>
        ) : (
          <CheckoutLoader show message="Loading secure payments interface." />
        )}
      </div>
    </CheckoutWrapper>
  );
};
