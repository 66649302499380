import { Heading, List, TextSize } from 'components/atoms/typography';

import type { OrderConfirmationDataFragment } from '@svelte/service/backend/graphql/gql';
import React from 'react';
import OrderItemReactified from '@svelte/reactify/order/OrderItemReactified';
import { useAppNavTree } from 'state/context/nav';

type Props = {
  order: OrderConfirmationDataFragment;
  className?: string;
};

// NOTE: this component is the same as CartItems, except it allows passing the items directly
// This is duplicated for now to simplify CartItems, and because it will eventually change with the
// confirmation page refactor
const OrderConfirmationItems: React.FC<Props> = ({ className, order }) => {
  const { items } = order;
  const appNavTree = useAppNavTree();

  return (
    <section aria-labelledby="summary-heading" className={className}>
      {!!items.length && (
        <Heading level={2} withoutSpacing size={TextSize.GreatPrimer}>
          Items
        </Heading>
      )}

      <div className="divide-y divide-primary-200 pb-rhythm4">
        <List blank withoutSpacing>
          {items.map(item => {
            return (
              <OrderItemReactified
                key={item.skuId}
                appNavTree={appNavTree}
                discount={order.discount}
                name={item.skuName}
                priceIntegerWithDecimals={item.price}
                productId={item.productId}
                qty={item.qty}
              />
            );
          })}
        </List>
      </div>
    </section>
  );
};

export default OrderConfirmationItems;
